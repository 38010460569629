import { default as _91_46_46_46slug_93eRNIR9oTxgMeta } from "/vercel/path0/frontend/pages/[...slug].vue?macro=true";
import { default as indexRpuN2ccdOnMeta } from "/vercel/path0/frontend/pages/[departmentZip]-wien/[healthProfessional]/index.vue?macro=true";
import { default as indexp7YQHEkhC2Meta } from "/vercel/path0/frontend/pages/[departmentZip]-wien/index.vue?macro=true";
import { default as _91_46_46_46slug_93YX2qR1r5pdMeta } from "/vercel/path0/frontend/pages/blog/[...slug].vue?macro=true";
import { default as indexiL6l7fL65LMeta } from "/vercel/path0/frontend/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93yfzyWoqHNuMeta } from "/vercel/path0/frontend/pages/blog/kategorie/[...slug].vue?macro=true";
import { default as indexYLr2zJPK69Meta } from "/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue?macro=true";
import { default as indexvwE9RrSPZ3Meta } from "/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/[location]/index.vue?macro=true";
import { default as indext8ycFFG4d9Meta } from "/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/index.vue?macro=true";
import { default as indexLFsmFtY1Q4Meta } from "/vercel/path0/frontend/pages/fachrichtung/[department]/index.vue?macro=true";
import { default as indexWGowZpCRw7Meta } from "/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue?macro=true";
import { default as indexKDxTLoBCe2Meta } from "/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue?macro=true";
import { default as indexQ6KXv9ACN2Meta } from "/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue?macro=true";
import { default as indexnbJk7VJ8iQMeta } from "/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/index.vue?macro=true";
import { default as fachrichtungenrCWIbx5u1kMeta } from "/vercel/path0/frontend/pages/fachrichtungen.vue?macro=true";
import { default as indexzkERW0S0RTMeta } from "/vercel/path0/frontend/pages/index.vue?macro=true";
import { default as _91slug_939PU56TURpzMeta } from "/vercel/path0/frontend/pages/team/[slug].vue?macro=true";
import { default as indexFvSL9JpnpRMeta } from "/vercel/path0/frontend/pages/team/index.vue?macro=true";
import { default as vielen_45dankfF5QQnfUeNMeta } from "/vercel/path0/frontend/pages/vielen-dank.vue?macro=true";
import { default as _91_46_46_46slug_93tLe8Zr7zkiMeta } from "~/pages/[...slug].vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93HqBsLitTXBMeta } from "~/pages/blog/[...slug].vue?macro=true";
import { default as indexwcDEFiGKxqMeta } from "~/pages/blog/index.vue?macro=true";
import { default as component_45stubhQvGvn3jj9Meta } from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhQvGvn3jj9 } from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/pages/[...slug].vue")
  },
  {
    name: "departmentZip-wien-healthProfessional",
    path: "/:departmentZip()-wien/:healthProfessional()",
    component: () => import("/vercel/path0/frontend/pages/[departmentZip]-wien/[healthProfessional]/index.vue")
  },
  {
    name: "departmentZip-wien",
    path: "/:departmentZip()-wien",
    component: () => import("/vercel/path0/frontend/pages/[departmentZip]-wien/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexiL6l7fL65LMeta || {},
    component: () => import("/vercel/path0/frontend/pages/blog/index.vue")
  },
  {
    name: "blog-kategorie-slug",
    path: "/blog/kategorie/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/pages/blog/kategorie/[...slug].vue")
  },
  {
    name: "fachrichtung-department-service-location-doctor",
    path: "/fachrichtung/:department()/:service()/:location()/:doctor()",
    meta: indexYLr2zJPK69Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue")
  },
  {
    name: "fachrichtung-department-service-location",
    path: "/fachrichtung/:department()/:service()/:location()",
    meta: indexvwE9RrSPZ3Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/[location]/index.vue")
  },
  {
    name: "fachrichtung-department-service",
    path: "/fachrichtung/:department()/:service()",
    meta: indext8ycFFG4d9Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/[department]/[service]/index.vue")
  },
  {
    name: "fachrichtung-department",
    path: "/fachrichtung/:department()",
    meta: indexLFsmFtY1Q4Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/[department]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service-doctor",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()/:doctor()",
    meta: indexWGowZpCRw7Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()",
    meta: indexKDxTLoBCe2Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()",
    meta: indexQ6KXv9ACN2Meta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin",
    path: "/fachrichtung/vorsorgemedizin",
    meta: indexnbJk7VJ8iQMeta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtung/vorsorgemedizin/index.vue")
  },
  {
    name: "fachrichtungen",
    path: "/fachrichtungen",
    meta: fachrichtungenrCWIbx5u1kMeta || {},
    component: () => import("/vercel/path0/frontend/pages/fachrichtungen.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/frontend/pages/index.vue")
  },
  {
    name: "team-slug",
    path: "/team/:slug()",
    meta: _91slug_939PU56TURpzMeta || {},
    component: () => import("/vercel/path0/frontend/pages/team/[slug].vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/vercel/path0/frontend/pages/team/index.vue")
  },
  {
    name: "vielen-dank",
    path: "/vielen-dank",
    component: () => import("/vercel/path0/frontend/pages/vielen-dank.vue")
  },
  {
    name: "heilmassage-1020-wien",
    path: "/heilmassage-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "hno-arzt-1020-wien",
    path: "/hno-arzt-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "internist-1180-wien",
    path: "/internist-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "kinderarzt-1020-wien",
    path: "/kinderarzt-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "osteopathie-1020-wien",
    path: "/osteopathie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "osteopathie-1180-wien",
    path: "/osteopathie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "psychotherapie-1180-wien",
    path: "/psychotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "psychotherapie-1020-wien",
    path: "/psychotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1180-wien",
    path: "/physiotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1020-wien",
    path: "/physiotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1040-wien",
    path: "/physiotherapie-1040-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "urologe-1180-wien",
    path: "/urologe-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "agb",
    path: "/agb",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "fuer-aerzte-therapeuten",
    path: "/fuer-aerzte-therapeuten",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "fuer-unternehmen",
    path: "/fuer-unternehmen",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "generali-rundum-gesund",
    path: "/generali-rundum-gesund",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "gratis-gesundheitscheck-wien",
    path: "/gratis-gesundheitscheck-wien",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "haeufige-fragen",
    path: "/haeufige-fragen",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "mitgliedschaft",
    path: "/mitgliedschaft",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-partnerzentrum",
    path: "/uniqa-partnerzentrum",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-vitalcheck",
    path: "/uniqa-vitalcheck",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-vorsorgeprogramm",
    path: "/uniqa-vorsorgeprogramm",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "vorsorge",
    path: "/vorsorge",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "wiener-staedtische",
    path: "/wiener-staedtische",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "blog-preview-slug",
    path: "/blog/preview/:slug",
    component: () => import("~/pages/blog/[...slug].vue")
  },
  {
    name: "blog-index-page",
    path: "/blog/:page(\\d+)",
    meta: indexwcDEFiGKxqMeta || {},
    component: () => import("~/pages/blog/index.vue")
  },
  {
    name: component_45stubhQvGvn3jj9Meta?.name,
    path: "/blog/1",
    component: component_45stubhQvGvn3jj9
  }
]
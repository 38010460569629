import type { Redirect } from "~/types/strapi/api/redirect/content-types/redirect/redirect";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const { params, path } = to;

    if (path.length <= 1 || path.startsWith("/__nuxt_error")) {
      return;
    }

    if (params.department === "gynakologie") {
      return navigateTo(
        {
          name: to.name ?? undefined,
          params: {
            ...params,
            department: "gynaekologie",
          },
          query: to.query,
        },
        {
          redirectCode: 301,
        },
      );
    }

    if (params.department === "diaetologie" || params.department === "ernaehrungsberatung") {
      return navigateTo(
        {
          name: to.name ?? undefined,
          params: {
            ...params,
            department: "ernaehrung",
          },
          query: to.query,
        },
        {
          redirectCode: 301,
        },
      );
    }

    if (params.department === "energetik") {
      return navigateTo(
        {
          name: to.name ?? undefined,
          params: {
            ...params,
            department: "komplementaermedizin",
          },
          query: to.query,
        },
        {
          redirectCode: 301,
        },
      );
    }

    const { data } = await useApiFetch<Payload<Redirect[]>>(
      `/api/redirects?pagination[pageSize]=1&filters[active]=true&filters[path]=${path}`,
    );
    const redirects = data.value;

    if (redirects?.data.length) {
      const redirect = redirects.data[0].attributes;
      const code = redirect.code.match(/(\d{3})/g) ?? [302];
      let target = redirect.target;

      if (typeof to.query === "object" && to.query !== null) {
        const delimeter = target.includes("?") ? "&" : "?";
        const query = Object.entries(to.query).reduce((acc, param) => {
          const key = param[0];
          const value = param[1];

          return `${acc}${key}${value ? "=" + value : ""}&`;
        }, delimeter);

        target += query.slice(0, -1);
      }

      return navigateTo(target, {
        external: true,
        redirectCode: code[0],
      });
    }
  }
});
